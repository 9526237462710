import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import uniqid from "uniqid"
// import fetch from "node-fetch"

import EpisodeLayout from "../components/episodeLayout"
import QuestionList from "../components/questionList"
import Summary from "../components/summary"
import styles from "./index.module.css"

const clientId = uniqid()

export default ({ data }) => {
  const [quizDone, setQuizDone] = useState(false)

  useEffect(() => {
    console.log("useEffect index", clientId)
    fetch(
      `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/register?epid=1&clientId=${clientId}`,
      // `http://localhost:3000/register?epid=1&clientId=${clientId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        mode: "no-cors",
      }
    ).catch(err => {
      console.log(`Can't register ${clientId}`, err)
    })
  }, [])

  return (
    <EpisodeLayout>
      <main>
        <article>
          <header>
            <h1 className={styles.headerTitle}>
              Industry News Quiz -{" "}
              <span className={styles.textBlue}>Episode 1</span>
            </h1>
          </header>
          <p>{data.allDataJson.edges[0].node.episodes[0].intro}</p>
          <QuestionList
            clientId={clientId}
            epid={1}
            setQuizDone={setQuizDone}
          />
          {quizDone && <Summary epid={1} clientId={clientId} />}
        </article>
      </main>
    </EpisodeLayout>
  )
}

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          episodes {
            intro
          }
        }
      }
    }
  }
`
