import React, { useState, useEffect } from "react"
import fetch from "node-fetch"
// import { LineChart, Line, YAxis, ResponsiveContainer } from "recharts"
import styles from "./question.module.css"

const getResult = (epid, clientId, setResult) => {
  fetch(
    `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/result?epid=${epid}`,
    // `http://localhost:3000/result?epid=${epid}`,
    {
      method: "GET",
      // mode: "no-cors",
    }
  )
    .then(async res => res.json())
    .then(res => {
      const totalQuizTakers = res.result.length
      const myResult = res.result.filter(record => record.clientId === clientId)
      const others = res.result.filter(record => record.clientId !== clientId)
      const betterThan = res.result.filter(
        record => record.correct_count < myResult[0].correct_count
      )
      const betterThanCount = betterThan.length
      const gotSameScore = others.filter(
        record => record.correct_count === myResult[0].correct_count
      )
      const gotSameScoreCount = gotSameScore.length

      const graphData = res.result.map(record => {
        return {
          percentage: Math.round((record.correct_count * 100) / record.total),
        }
      })

      setResult({
        correct: myResult.length > 0 ? myResult[0].correct_count : 0,
        total: myResult.length > 0 ? myResult[0].total : 0,
        betterThanCount,
        gotSameScoreCount,
        totalQuizTakers,
        graphData,
      })
    })
    .catch(err => {
      console.log("Error:", err)
    })
}

/*
const CustomizedDot = props => {
  const { cx, cy, points, index } = props

  if (index === points.length - 1) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="green"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    )
  }

  return <></>
}
*/

export default props => {
  const { epid, clientId } = props
  const [result, setResult] = useState({
    correct: 0,
    total: 0,
    betterThanCount: 0,
    gotSameScoreCount: 0,
    totalQuizTakers: 0,
    graphData: [],
  })

  useEffect(() => {
    console.log("Summary useEffect")
    getResult(epid, clientId, setResult)
  }, [epid, clientId])

  return (
    <div className={styles.summary}>
      <div className={styles.result}>
        {result.correct} out of {result.total}
      </div>
      <div className={styles.comment}>
        You did better than{" "}
        {Math.round((result.betterThanCount * 100) / result.totalQuizTakers)}%
        of those who took the quiz.{" "}
        {Math.round((result.gotSameScoreCount * 100) / result.totalQuizTakers)}%
        of quiz takers got the same score as you.
      </div>
      {/* <div className={styles.graph}>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={result.graphData}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <YAxis
              interval="preserveStartEnd"
              unit="%"
              tickLine={false}
              axisLine={false}
              tick={{ fill: "gray", fontSize: ".75rem" }}
            />
            <Line
              type="natural"
              dataKey="percentage"
              stroke="#1BC3ED"
              dot={<CustomizedDot />}
            />
          </LineChart>
        </ResponsiveContainer>
      </div> */}
    </div>
  )
}
