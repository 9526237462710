import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import fetch from "node-fetch"
import styles from "./question.module.css"
import MultipleChoice from "./MultipleChoice"
import MultipleResponse from "./MultipleResponse"
import FillinBlank from "./FillinBlank"
import Feedback from "../components/feedback"
import { checkAnswer } from "../utils/quizUtil"

const answerQuestion = async (
  clientId,
  epid,
  qid,
  qParams,
  setAnswered,
  setAnsweredError,
  setStats,
  setStatsError,
  numQuestionDone,
  setNumQuestionDone,
  answeredMasterLockQuestions,
  setAnsweredMasterLockQuestions
) => {
  const isCorrect = checkAnswer(qParams)
  let endpointUrl = ""

  if (qParams.type === "polling") {
    endpointUrl = `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/answer?clientId=${clientId}&epid=${epid}&qid=${qid}&type=${
      qParams.type
    }&yes=${qParams.choiceIndex === 0}&no=${qParams.choiceIndex === 1}`
  } else {
    endpointUrl = `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/answer?clientId=${clientId}&epid=${epid}&qid=${qid}&type=${qParams.type}&is_correct=${isCorrect}`
    // endpointUrl = `http://localhost:3000/answer?clientId=${clientId}&epid=${epid}&qid=${qid}&type=${qParams.type}&is_correct=${isCorrect}`
  }
  fetch(endpointUrl, {
    method: "POST",
    // mode: "no-cors",
  })
    .then(async result => {
      if (result.ok && result.status === 200) {
        setAnswered(true)
        setNumQuestionDone(numQuestionDone + 1)
        loadStats(epid, qid, qParams.type, setStats, setStatsError)
        if (qParams.unlock && qParams.unlock.length > 0) {
          setAnsweredMasterLockQuestions(
            answeredMasterLockQuestions.concat(qid)
          )
        }
      }
    })
    .catch(e => {
      console.log("Error while submitting answer!", e)
      setAnsweredError(
        "Ooops! Error while submitting your answer. Please try again or refresh the page!"
      )
    })
}

const loadStats = async (epid, qid, type, setStats, setStatsError) => {
  fetch(
    `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/stats?epid=${epid}&qid=${qid}&type=${type}`,
    // `http://localhost:3000/stats?epid=${epid}&qid=${qid}&type=${type}`,
    {
      method: "GET",
      // mode: "no-cors",
    }
  )
    .then(res => res.json())
    .then(res => {
      const stat1 = parseInt(res.stats.stat1)
      const stat2 = parseInt(res.stats.stat2)
      setStats({ stat1, stat2 })
    })
    .catch(e => {
      console.log("Error while getting stats!", e)
      setStatsError("Weird! I can't seem to load response stats. :(")
    })
  return
}

const Question = props => {
  const [answered, setAnswered] = useState(false)
  useEffect(() => {
    if (answered) {
      setLoading(false)
      setStatsLoading(false)
    }
  }, [answered])

  // Depending question type, stat1 and stat2 can have different meanings:
  // For question type "polling":
  //   - stat1 is "yes" count
  //   - stat2 is "no" count
  // For other question types:
  //   - stat1 is "correct" count
  //   - stat2 is "total" count
  const [stats, setStats] = useState({ stat1: 0, stat2: 0 })
  useEffect(() => {
    if (stats.stat1 > 0 || stats.stat2 > 0) {
      setStatsLoading(false)
    }
  }, [stats])

  const [answeredError, setAnsweredError] = useState("")
  const [statsError, setStatsError] = useState("")
  const [loading, setLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)
  const {
    epid,
    clientId,
    q,
    index,
    total,
    numQuestionDone,
    setNumQuestionDone,
    answeredMasterLockQuestions,
    setAnsweredMasterLockQuestions,
  } = props
  let questionStyles
  if (answered) {
    questionStyles = [styles.question, styles.answered].join(" ")
  } else {
    if (q.unlockafter && !answeredMasterLockQuestions.includes(q.unlockafter)) {
      questionStyles = [styles.question, styles.locked, styles.unanswered].join(
        " "
      )
    } else {
      questionStyles = [styles.question, styles.unanswered].join(" ")
    }
  }

  return (
    <div className={questionStyles} key={index}>
      <div className={styles.counter}>{`${index + 1} of ${total}`}</div>
      {q.unlockafter && (
        <div className={styles.lockedMessage}>
          Answer question {q.unlockafter} to unlock
        </div>
      )}
      <div className={styles.questionBody}>
        <div dangerouslySetInnerHTML={{ __html: q.statement }}></div>
        {q.figure && (
          <figure>
            {q.figure.image && (
              <Img
                fluid={q.figure.image.childImageSharp.fluid}
                alt={q.figure.alt || ""}
              />
            )}
            {(q.figure.caption || q.figure.credit) && (
              <figcaption>
                {q.figure.caption && (
                  <span className={styles.figCaption}>{q.figure.caption}</span>
                )}
                {q.figure.credit && (
                  <span className={styles.figCredit}>
                    &nbsp;{q.figure.credit}
                  </span>
                )}
              </figcaption>
            )}
          </figure>
        )}
        <div className={styles.choices}>
          {loading && (
            <div className={styles.loader}>
              <div className={styles.ldsRipple}>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {["multiplechoice", "polling"].includes(q.type) && (
            <MultipleChoice
              qid={q.id}
              type={q.type}
              choices={q.choices}
              answer={parseInt(q.answer[0])}
              answered={answered}
              unlock={q.unlock}
              handleClick={(qid, qParams) => {
                setLoading(true)
                answerQuestion(
                  clientId,
                  epid,
                  qid,
                  qParams,
                  setAnswered,
                  setAnsweredError,
                  setStats,
                  setStatsError,
                  numQuestionDone,
                  setNumQuestionDone,
                  answeredMasterLockQuestions,
                  setAnsweredMasterLockQuestions
                )
              }}
            />
          )}
          {q.type === "fillinblank" && (
            <FillinBlank
              qid={q.id}
              type={q.type}
              answer={q.answer}
              answered={answered}
              handleClick={(qid, qParams) => {
                setLoading(true)
                answerQuestion(
                  clientId,
                  epid,
                  qid,
                  qParams,
                  setAnswered,
                  setAnsweredError,
                  setStats,
                  setStatsError,
                  numQuestionDone,
                  setNumQuestionDone,
                  answeredMasterLockQuestions,
                  setAnsweredMasterLockQuestions
                )
              }}
            />
          )}
          {q.type === "multipleresponse" && (
            <MultipleResponse
              qid={q.id}
              type={q.type}
              choices={q.choices}
              answer={q.answer}
              answered={answered}
              handleClick={(qid, qParams) => {
                setLoading(true)
                answerQuestion(
                  clientId,
                  epid,
                  qid,
                  qParams,
                  setAnswered,
                  setAnsweredError,
                  setStats,
                  setStatsError,
                  numQuestionDone,
                  setNumQuestionDone,
                  answeredMasterLockQuestions,
                  setAnsweredMasterLockQuestions
                )
              }}
            />
          )}
        </div>
        {answered && answeredError.length === 0 && (
          <Feedback
            type={q.type}
            feedback={q.feedback}
            stats={stats}
            statsError={statsError}
            statsLoading={statsLoading}
            source={q.source}
          />
        )}
        {answeredError.length > 0 && (
          <p className={styles.error}>{answeredError}</p>
        )}
      </div>
    </div>
  )
}

export default props => {
  const [numQuestionDone, setNumQuestionDone] = useState(0)
  const [totalNumQuestions, setTotalNumQuestions] = useState(0)
  const [
    answeredMasterLockQuestions,
    setAnsweredMasterLockQuestions,
  ] = useState([])
  const { setQuizDone } = props

  useEffect(() => {
    if (numQuestionDone > 0) {
      if (numQuestionDone === totalNumQuestions) {
        setQuizDone(true)
      }
    }
  }, [numQuestionDone, totalNumQuestions, setQuizDone])

  return (
    <StaticQuery
      query={graphql`
        query QuestionQuery {
          allDataJson {
            edges {
              node {
                episodes {
                  questions {
                    id
                    statement
                    type
                    unlock
                    unlockafter
                    answer
                    feedback
                    source {
                      label
                      url
                    }
                    choices
                    figure {
                      caption
                      credit
                      image {
                        childImageSharp {
                          fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { clientId, epid } = props
        const { questions } = data.allDataJson.edges[0].node.episodes[epid - 1]
        const total = questions.length
        setTotalNumQuestions(total)
        const questionList = questions.map((q, index) => {
          return (
            <Question
              epid={epid}
              clientId={clientId}
              q={q}
              index={index}
              total={total}
              numQuestionDone={numQuestionDone}
              setNumQuestionDone={setNumQuestionDone}
              answeredMasterLockQuestions={answeredMasterLockQuestions}
              setAnsweredMasterLockQuestions={setAnsweredMasterLockQuestions}
              key={index}
            />
          )
        })

        return questionList
      }}
    />
  )
}
